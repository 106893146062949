@import url("https://cdn.jsdelivr.net/gh/thecoolagency/thecoolcdn@v1.0.0/css/fix.tca.css");
@import url("https://cdn.jsdelivr.net/gh/thecoolagency/thecoolcdn@v1.0.0/css/animations.tca.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

:root {
    --theme-bg-color: rgba(16 18 27 / 40%);
    --border-color: rgba(113 119 144 / 25%);
    --theme-color: #f9fafb;
    --inactive-color: rgb(113 119 144 / 78%);
    --body-font: "Poppins", sans-serif;
    --hover-menu-bg: rgba(12 15 25 / 30%);
    --content-title-color: #999ba5;
    --content-bg: rgb(146 151 179 / 13%);
    --button-inactive: rgb(249 250 251 / 55%);
    --dropdown-bg: #21242d;
    --dropdown-hover: rgb(42 46 60);
    --popup-bg: rgb(22 25 37);
    --search-bg: #14162b;
    --overlay-bg: rgba(36, 39, 59, 0.3);
    --scrollbar-bg: rgb(1 2 3 / 40%);
}

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: var(--body-font);
    background: linear-gradient(269.26deg,#8f2384 6.59%,#213295 94.04%);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
}
@media screen and (max-width: 480px) {
    body {
        padding: 0.8em;
    }
}

img {
    max-width: 100%;
}

.line {
    white-space: nowrap;
}

::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-bg);
    border-radius: 10px;
}

.app {
    background-color: var(--theme-bg-color);
    max-width: 1250px;
    max-height: 860px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    border-radius: 14px;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    font-size: 15px;
    font-weight: 500;
}

.header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 58px;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    padding: 0 30px;
    white-space: nowrap;
}
@media screen and (max-width: 480px) {
    .header {
        padding: 0 16px;
    }
}

.header-menu {
    display: flex;
    align-items: center;
}

.header-menu a, .header-menu span {
    padding: 15px 30px;
    text-decoration: none;
    color: var(--inactive-color);
    border-bottom: 1px solid transparent;
    transition: 0.3s;
}

@media screen and (max-width: 610px) {
    .header-menu a:not(.main-header-link) {
        display: none;
    }
}
.header-menu a.is-active,
.header-menu span.is-active,
.header-menu a:hover:not([disabled]),
.header-menu span:hover:not([disabled]) {
    color: var(--theme-color);
    border-bottom: 1px solid var(--theme-color);
}

.menu-circle {
    width: 15px;
    height: 15px;
    background-color: #f96057;
    border-radius: 50%;
    box-shadow: 24px 0 0 0 #f8ce5261, 48px 0 0 0 #5fcf6566;
    margin-right: 195px;
    flex-shrink: 0;
    transition: background-color 100ms linear;
}

.menu-circle:hover {
    background-color: #ea4238;
}

@media screen and (max-width: 945px) {
    .menu-circle {
        display: none;
    }
}

.header-account {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.connect {
    width: 100%;
    height: 100%;
    border: none;
    background-color: var(--search-bg);
    border-radius: 4px;
    line-height: 2.5rem;
    font-weight: 500;
    padding: 0 1rem;
    box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 16px 48%;
    color: var(--theme-color);
}

.input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgba(20, 22, 43, 0.6);
    border-radius: 4px;
    font-size: 16px;
    padding: 0.5rem 1rem;
    font-family: Poppins;
    box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
    color: var(--theme-color);
}

.highlight {
    width: 100%;
    font-size: 16px;
    background-color: #1b1b1e5e;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    color: var(--theme-color);
}

input::-moz-placeholder {
    font-family: var(--body-font);
    color: var(--inactive-color);
    font-size: 10.5px;
    font-weight: 300;
}

.wrapper {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.left-side {
    flex-basis: 240px;
    border-right: 1px solid var(--border-color);
    padding: 26px;
    overflow: auto;
    flex-shrink: 0;
}
@media screen and (max-width: 945px) {
    .left-side {
        display: none;
    }
}

.side-wrapper + .side-wrapper {
    margin-top: 20px;
}

.side-title {
    color: var(--inactive-color);
    margin-bottom: 14px;
}

.side-menu {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}
.side-menu a {
    text-decoration: none;
    color: var(--theme-color);
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 10px;
    font-size: 14px;
    border-radius: 6px;
    transition: 0.3s;
}
.side-menu a:hover {
    background-color: var(--hover-menu-bg);
}
.side-menu svg {
    width: 16px;
    margin-right: 8px;
}

.updates {
    position: relative;
    top: 0;
    right: 0;
    margin-left: auto;
    width: 18px;
    height: 18px;
    font-size: 11px;
}

.main-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    height: 58px;
    flex-shrink: 0;
}
@media screen and (max-width: 749px) {
    .main-header {
        height: 20px
    }
}
.main-header .header-menu {
    margin-left: 150px;
    justify-content: center;
    width: 100%;
    justify-content: flex-end;
}
@media screen and (max-width: 1055px) {
    .main-header .header-menu {
        margin: auto;
    }
}
.main-header .header-menu a {
    padding: 20px 24px;
}

.main-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.menu-link-main {
    text-decoration: none;
    color: var(--theme-color);
    padding: 0 30px;
}
@media screen and (max-width: 1055px) {
    .menu-link-main {
        display: none;
    }
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    color: var(--theme-color);
    padding: 20px 40px;
    height: 100%;
    overflow: auto;
    background-color: var(--theme-bg-color);
}

@media screen and (max-width: 510px) {
    .content-wrapper {
        padding: 20px;
    }
}

.content-wrapper-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-color: #14162b;
    border-radius: 14px;
    padding: 20px 40px;
}

.img-content {
    font-weight: 500;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin: 0;
}

.img-content svg {
    width: 28px;
    margin-right: 14px;
}

.content-text {
    font-weight: 400;
    font-size: 14px;
    margin-top: 16px;
    line-height: 1.7em;
    color: #ebecec;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-wrapper-context {
    max-width: 350px;
}

.content-button {
    background-color: #3a6df0;
    border: none;
    padding: 8px 26px;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    height: 32px;
}

.content-wrapper-img {
    width: 40%;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top: -25px;
    -o-object-position: center;
    object-position: center;
}
@media screen and (max-width: 570px) {
    .content-wrapper-img {
        width: 110px;
    }
}

.content-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.content-section-title {
    color: var(--content-title-color);
    margin-bottom: 14px;
}

.products {
    display: flex;
    align-items: center;
    width: 150px;
}
@media screen and (max-width: 480px) {
    .products {
        width: 120px;
    }
}

.status {
    margin-left: auto;
    width: 140px;
    font-size: 15px;
    position: relative;
}
@media screen and (max-width: 700px) {
    .status {
        display: none;
    }
}
.status-circle {
    width: 6px;
    height: 6px;
    background-color: #396df0;
    position: absolute;
    border-radius: 50%;
    top: 4px;
    left: -20px;
}
.status-circle.green {
    background-color: #3bf083;
}
.status-button {
    font-size: 15px;
    margin-top: 0;
    padding: 6px 24px;
}
@media screen and (max-width: 390px) {
    .status-button {
        padding: 6px 14px;
    }
}
.status-button.open {
    background: none;
    color: var(--button-inactive);
    border: 1px solid var(--button-inactive);
}
.status-button:not(.open):hover {
    color: #fff;
    border-color: #fff;
}

.content-button:not(.open):hover {
    background: #1e59f1;
}

.button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 187px;
    margin-left: auto;
}
@media screen and (max-width: 480px) {
    .button-wrapper {
        width: auto;
    }
}

.apps-card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% + 20px);
}

.app-card {
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px);
    font-size: 16px;
    background-color: var(--content-bg);
    border-radius: 14px;
    border: 1px solid var(--theme-bg-color);
    padding: 20px;
    cursor: pointer;
    transition: 0.3s ease;
}

.app-card:hover {
    transform: scale(1.02);
    background-color: var(--theme-bg-color);
}

.app-card svg {
    width: 28px;
    border-radius: 6px;
    margin-right: 12px;
    flex-shrink: 0;
}

.app-card {
    margin-right: 20px;
    margin-bottom: 20px;
}

.app-card span {
    display: flex;
    align-items: center;
}

.app-card__subtext {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6em;
    margin-top: 20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
}

.app-card-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-top: 16px;
}

@media screen and (max-width: 1110px) {
    .app-card {
        width: calc(50% - 20px);
    }
    .app-card:last-child {
        margin-top: 20px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 565px) {
    .app-card {
        width: calc(100% - 20px);
        margin-top: 10px;
    }
    .app-card + .app-card {
        margin-left: 0;
    }
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: .4rem 0;
}

.footer a {
    display: block;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.15rem;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.credit {
    font-size: 1.5rem;
    bottom: 1rem;
    left: 1rem;
    color: rgba(255, 255, 255, 0.85);
}

/* toastify */

.Toastify__toast-container {
    position: fixed;
    padding: 0 4px;
    color: #fff;
}

.Toastify__toast-container--top-right {
    top: 2em;
    right: 1em;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        width: 95vw;
        padding: 0;
        left: 2.5vw;
        margin: 0;
    }

    .Toastify__toast-container--top-right {
        top: 13.5vw;
        transform: translateX(0);
    }
}

.Toastify__toast {
    position: relative;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 12px;
    background-color: var(--content-bg);
    border-radius: 1.4rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    cursor: pointer;
    transition: 0.3s ease;
}

.Toastify__toast:hover {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.Toastify__toast-body {
    margin: auto 0;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 6px 12px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.Toastify__toast-body > div:last-child {
    -ms-flex: 1;
    flex: 1;
}

.Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 0.4s;
}

.Toastify--animate-icon {
    animation-fill-mode: both;
    animation-duration: 0.3s;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast {
        margin-bottom: 0;
        border-radius: 0;
    }
}

.Toastify__close-button {
    cursor: pointer;
    border-radius: 50rem;
    padding-left: 0;
    padding-right: 0;
    min-width: 16px;
    height: 16px;
    padding: 0;
    opacity: 0.4;
    transition: opacity 100ms linear;
    border: 1px solid;
    border-left-color: grey;
    border-top-color: grey;
}

.Toastify__close-button > svg {
    fill: #ffffff;
    height: 12.5px;
    width: 12.5px;
    padding: 1px 0 0 1px;
    margin: 0 auto;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
    opacity: 0.65;
}

.Toastify__spinner {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 2px solid;
    border-radius: 100%;
    border-color: var(--toastify-spinner-color-empty-area);
    border-right-color: var(--toastify-spinner-color);
    animation: spin 0.65s linear infinite;
}

.Toastify__bounce-enter--top-right {
    animation-name: slideInRight;
}

.Toastify__bounce-exit--top-right {
    animation-name: slideOutRight;
}

.Toastify__slide-enter--top-right {
    animation-name: slideInRight;
}

.Toastify__slide-exit--top-right {
    animation-name: slideOutRight;
}

/* Modal */

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 98;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.modal {
    position: relative;
    z-index: 99;
    width: 100%;
    max-width: 60rem;
    max-height: 90%;
    margin: 0 auto;
    overflow: scroll;
}

.modal-close {
    position: absolute;
    top: 0rem;
    right: 0;
    padding: 10px 20px;
    border: 0;
    -webkit-appearance: none;
    background: none;
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 66;
}

@media screen and (max-width: 768px) {
    .modal {
        max-height: 80%;
    } 

    .modal-close {
        top: 0rem;
        right: 8px;
        padding: 10px;
        color: black;
    }
}

.modal-body {
    padding: 1.25rem 1.5rem;
    border-radius: 4px;
    background-color: white;
    color: #fff;
    background-color: var(--theme-bg-color);
    border-radius: 14px;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    font-size: 15px;
    font-weight: 500;
}

.modal-transition-enter .overlay {
    opacity: 0;
}

.modal-transition-enter-active .overlay {
    opacity: 1;
    transition: opacity 200ms;
}

.modal-transition-exit-active .overlay {
    opacity: 1;
}

.modal-transition-exit-active .overlay {
    opacity: 0;
    transition: opacity 200ms;
}

.modal-transition-enter .modal {
    opacity: 0;
    transform: scale(0.95) translateY(-30px);
}

.modal-transition-enter-active .modal {
    opacity: 1;
    transform: translateX(0) translateY(0);
    transition: opacity 200ms, transform 200ms;
}

.modal-transition-exit .modal {
    opacity: 1;
}

.modal-transition-exit-active .modal {
    opacity: 0;
    transform: scale(0.95) translateY(-30px);
    transition: opacity 200ms, transform 200ms;
}
